$(() => {
  const $selectTypo = $('#js-selectTypo'),
    $languagesList = $('#js-languagesList'),
    $totalValue = $('#js-totalValue'),
    now = genericDate.format(new Date()),
    arrDateNow = now.split('-'),
    isStepParam = window.location.href.indexOf('?step=participants') > -1;

  const counter = {
    adults: 1,
    childs: 0,
    babies: 0
  };

  let typoFormat;
  let totalPrice = 0;

  // ---
  if (genericDate.diff(new Date(), new Date(arrDateNow[0], arrDateNow[1], arrDateNow[2], 17, 0))) {
    typoFormat = 'day';
    $('.js-typo[data-code="halfday"]').addClass('u-Hidden');
  } else {
    typoFormat = 'halfday';
    $('.js-typo[data-code="day"]').addClass('u-Hidden');
  }

  const today = new Date(now).toLocaleDateString(undefined, {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  }).replace(/\./gi, '');

  $('#js-date').html(today.charAt(0).toUpperCase() + today.slice(1));

  // ---
  $('.js-flag').on('click', event => {
    languageCode = $(event.currentTarget).data('code');

    if (isStepParam) {
      event.preventDefault();

      switch (languageCode) {
        case 'fr':
        case 'de':
        case 'nl':
          marketCode = languageCode;
          break;
        case 'en':
          marketCode = 'gb';
          break;
      }

      $languagesList.addClass('u-Hidden');
      $selectTypo.removeClass('u-Hidden');

      $('#js-adultPrice').html(dispos[typoFormat].formatted_adult_price);
      $('#js-childPrice').html(dispos[typoFormat].formatted_child_price);

      totalPrice = parseFloat(dispos[typoFormat].adult_price);
      $totalValue.html(parseFloat(totalPrice) + ' €');
    }
  });

  // ---
  if (isStepParam) {
    $('.js-flag[data-code="' + languageCode + '"]').eq(0).click();
  }

  // --- set quantity
  $('.js-traveller--remove:not(.js-traveller--disabled), .js-traveller--add:not(.js-traveller--disabled)').on('click', event => {
    event.preventDefault();

    const rules = {
      adults: {
        max: 9,
        min: 1
      },
      babies: {
        max: 10,
        min: 0
      },
      childs: {
        max: 8,
        min: 0
      },
      persons: {
        max: 9,
        min: 1
      }
    };

    const $currentTarget = $(event.currentTarget),
      $parentTarget = $currentTarget.parents('.js-travellerBlock').eq(0),
      $quantityBlock = $parentTarget.find('.js-travellerQuantity').eq(0),
      type = $parentTarget.attr('id').split('js-traveller-')[1],
      maximum = parseInt(rules[type].max, 10),
      minimum = parseInt(rules[type].min, 10),
      nbAdultsSelected = parseInt($('.js-travellerQuantity[data-role="adults"]').html(), 10),
      nbChildrenSelected = parseInt($('.js-travellerQuantity[data-role="childs"]').html(), 10),
      nbBabiesSelected = parseInt($('.js-travellerQuantity[data-role="babies"]').html(), 10);

    counter['adults'] = ($currentTarget.hasClass('js-traveller--add') && type == 'adults') ? nbAdultsSelected + 1 : nbAdultsSelected;
    counter['childs'] = ($currentTarget.hasClass('js-traveller--add') && type == 'childs') ? nbChildrenSelected + 1 : nbChildrenSelected;
    counter['babies'] = ($currentTarget.hasClass('js-traveller--add') && type == 'babies') ? nbBabiesSelected + 1 : nbBabiesSelected;

    let counterAll = counter['adults'] + counter['childs'];

    const $addTravellerAdultsAndChilds = $('#js-traveller-adults .js-traveller--add, #js-traveller-childs .js-traveller--add');
    $addTravellerAdultsAndChilds.removeClass('u-Reservation--disabled js-traveller--disabled');
    if (counterAll == rules.persons.max) {
      $addTravellerAdultsAndChilds.addClass('u-Reservation--disabled js-traveller--disabled');
    }

    if ($currentTarget.hasClass('js-traveller--remove')) {
      if (counter[type] > minimum) {
        counter[type] = --counter[type];

        if (type != 'babies') {
          counterAll--;

          const priceByType = (type == 'adults') ? dispos[typoFormat].adult_price : (type == 'childs') ? dispos[typoFormat].child_price : 0;
          totalPrice -= parseFloat(priceByType);

          $totalValue.html(totalPrice + ' €');
        }
      } else {
        counter[type] = minimum;
      }

      $quantityBlock.text(counter[type]);

      if (counter[type] == minimum) {
        $currentTarget.addClass('u-Reservation--disabled js-traveller--disabled');
      }

      if (counterAll < rules.persons.max) {
        $('.js-traveller--add').removeClass('u-Reservation--disabled js-traveller--disabled');
      }
    } else {
      if (counterAll < rules.persons.max) {
        if (counter[type] <= maximum) {
          if (type != 'babies') {
            counterAll++;

            const priceByType = (type == 'adults') ? dispos[typoFormat].adult_price : (type == 'childs') ? dispos[typoFormat].child_price : 0;
            totalPrice += parseFloat(priceByType);

            $totalValue.html(totalPrice + ' €');
          }

          if (type == 'adults' && marketCode.toLowerCase() == 'nl' && counter[type] == 0) {
            counter[type] = rules.adults.min;
          }

          $parentTarget.find('.js-traveller--remove').eq(0).removeClass('u-Reservation--disabled js-traveller--disabled');
          $quantityBlock.text(counter[type]);

          if (counter[type] == maximum) {
            $currentTarget.addClass('u-Reservation--disabled js-traveller--disabled');
          }
        }
      } else {
        if (counter[type] <= maximum) {
          if (type == 'adults' && marketCode.toLowerCase() == 'nl' && counter[type] == 0) {
            counter[type] = rules.adults.min;
          }

          $parentTarget.find('.js-traveller--remove').eq(0).removeClass('u-Reservation--disabled js-traveller--disabled');
          $quantityBlock.text(counter[type]);

          if (counter[type] == maximum) {
            $currentTarget.addClass('u-Reservation--disabled js-traveller--disabled');
          }
        }
      }
    }
  });

  // --- create form data and submit
  $('.js-submit').on('click', event => {
    event.preventDefault();

    const typo = dispos[typoFormat],
      nbAdultsSelected = parseInt($('.js-travellerQuantity[data-role="adults"]').html(), 10),
      nbChildrenSelected = parseInt($('.js-travellerQuantity[data-role="childs"]').html(), 10);

    let nbAccoms = nbAdultsSelected + nbChildrenSelected;

    const $form = $('<form>', {
      method: 'post',
      class: 'u-Hidden'
    });

    const arrayHiddenForm = [
      $('<input>', {type: 'hidden', name: 'dateIn', value: typo.date}),
      $('<input>', {type: 'hidden', name: 'branding', value: typo.offer_code}),
      $('<input>', {type: 'hidden', name: 'codedes', value: typo.offer_code}),
      $('<input>', {type: 'hidden', name: 'accoms[' + typo.housing_code + ']', value: nbAccoms}),
      $('<input>', {type: 'hidden', name: 'owner_product', value: typo.owner_product}),
      $('<input>', {type: 'hidden', name: 'duration', value: typo.duration}),
      $('<input>', {type: 'hidden', name: 'nbSelectedAdults', value: nbAdultsSelected}),
      $('<input>', {type: 'hidden', name: 'nbSelectedChilds', value: nbChildrenSelected}),
      $('<input>', {type: 'hidden', name: 'nbSelectedBabies', value: counter['babies']}),
      $('<input>', {type: 'hidden', name: 'languageCode', value: languageCode.toUpperCase()}),
      $('<input>', {type: 'hidden', name: 'marketCode', value: marketCode.toUpperCase()}),
      $('<input>', {type: 'hidden', name: 'universId', value: universId}),
      $('<input>', {type: 'hidden', name: 'referertoeresa', value: referer}),
      $('<input>', {type: 'hidden', name: 'codeportal', value: codeportal})
    ];

    let actionCodes = [];

    for (let i = 0; i < counter['childs']; i++) {
      actionCodes.push(typo.action_code);
    }

    arrayHiddenForm.push($('<input>', {
      type: 'hidden',
      name: 'actionCode',
      value: actionCodes.join(',')
    }));

    _.each(arrayHiddenForm, $inputHidden => {
      $form.append($inputHidden);
    });

    $selectTypo.append($form);
    $form.submit().remove();
  });
});